<template>
  <div>
    <div
      v-if="editor"
      class="editor-window"
    >
      <div
        class="editor-buttons flex flex-col md:flex-row justify-between items-center gap-2 md:gap-8"
      >
        <div>
          <button
            v-for="button in buttons"
            :key="`button_${button.title}`"
            @click="button.click()"
            class="editor-button"
            :class="[
              button.active !== undefined && editor.isActive(button.active, button.activeExtra)
                ? 'active'
                : '',
              ...(button.class ? button.class : []),
            ]"
            :title="button.title"
            :disabled="button.disabled === undefined ? false : button.disabled()"
          >
            <i :class="button.icon"></i>
          </button>
        </div>
        <slot name="extra"></slot>
      </div>

      <editor-content
        class="editor-content"
        :editor="editor"
        ref="editor"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Image from '@tiptap/extension-image';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';

const _ = require('lodash');

export default {
  name: 'TextEditor',
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      buttons: [
        {
          click: () => {
            this.editor.chain().focus().toggleCode().run();
          },
          active: 'code',
          title: 'Font',
          icon: 'fas fa-font',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleBold().run();
          },
          active: 'bold',
          title: 'Bold',
          icon: 'fas fa-bold',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleItalic().run();
          },
          active: 'italic',
          title: 'Italic',
          icon: 'fas fa-italic',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleStrike().run();
          },
          active: 'strike',
          title: 'Strike',
          icon: 'fas fa-strikethrough',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleUnderline().run();
          },
          active: 'underline',
          title: 'Underline',
          icon: 'fas fa-underline',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleHeading({ level: 1 }).run();
          },
          active: 'heading',
          activeExtra: { level: 1 },
          title: 'Heading',
          icon: 'fas fa-heading',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleBulletList().run();
          },
          active: 'bulletList',
          title: 'Bullet list',
          icon: 'fas fa-list-ul',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleOrderedList().run();
          },
          active: 'orderedList',
          title: 'Ordered list',
          icon: 'fas fa-list-ol',
        },
        {
          click: () => {
            this.editor.chain().focus().toggleTaskList().run();
          },
          active: 'taskList',
          title: 'Task list',
          icon: 'far fa-check-square',
        },
        {
          click: () => {
            this.editor.chain().focus().undo().run();
          },
          disabled: () => (!this.editor.can().undo()),
          title: 'Undo',
          icon: 'fas fa-undo',
        },
        {
          click: () => {
            this.editor.chain().focus().redo().run();
          },
          disabled: () => (!this.editor.can().redo()),
          title: 'Redo',
          icon: 'fas fa-redo',
        },
      ],
      scheduleUpdate: _.debounce(() => {
        this.$emit('input', this.editor.getHTML());
      }, 250),
    };
  },
  methods: {
    onResize() {
      if (!this.$refs.editor.$el.firstChild) return;
      const newHeight = window.innerHeight - this.$refs.editor.$el.firstChild.offsetTop - 48;
      this.$refs.editor.$el.firstChild.style.height = `${newHeight}px`;
    },
  },
  created() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Heading.configure({
          levels: [1],
        }),
        BulletList,
        OrderedList,
        Image.configure({
          allowBase64: true,
        }),
        TaskList,
        TaskItem.configure({
          nested: false,
        }),
      ],
      onUpdate: () => {
        this.scheduleUpdate();
      },
    });
    this.$nextTick().then(() => {
      this.onResize();
    });
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
  .editor-window {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid #dadce0;
    border-radius: 5px;
  }

  .editor-buttons {
    background-color: #f5f5f5;
    padding: 8px 16px;
  }

  .editor-button {
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
  }

  .editor-content {
    padding: 8px 16px 16px 16px;
    height: 100%;
  }

  .editor-button.active {
    background-color: #363636;
    color: #f5f5f5;
  }

  .editor-button[disabled] {
    color: #d4d4d4;
    cursor: default;
  }

  .ProseMirror {
    overflow-y: auto;
  }

  .ProseMirror code {
    padding: 0;
    color: black;
  }

  .ProseMirror h1 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-wrap: pretty;
  }

  .ProseMirror ul, ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }

  .ProseMirror ul {
    list-style: disc;
  }

  .ProseMirror ol {
    list-style: decimal;
  }

  .ProseMirror img {
    border: 2px solid transparent;
  }

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror-selectednode {
    border-color: #999 !important;
  }

  .ProseMirror {
    ul[data-type="taskList"] {
      list-style: none;
      margin-left: 0;
      padding: 0;

      li {
        align-items: center;
        display: flex;
        gap: 0.5rem;

        > label {
          flex: 0 0 auto;
          margin: 0;
          user-select: none;
        }

        > div {
          flex: 1 1 auto;
        }
      }

      input[type="checkbox"] {
        cursor: pointer;
        vertical-align: middle;
      }

      ul[data-type="taskList"] {
        margin: 0;
      }
    }
  }
</style>
